import React from 'react'
import Breadcrumbs from '../../components/Breadcrumbs'
import ImageHeader from '../../components/ImageHeader'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBookmark} from '@fortawesome/pro-solid-svg-icons'
import IndexFilter from '../../components/IndexFilter'
import AdComponent from "../../components/AdComponent"
import Layout from "../../components/Layout"
import Seo from '../../components/Seo'
import {useStaticQuery, graphql} from 'gatsby'
import '../../styles/index.scss'

const ScripturesPage = () => {
    const data = useStaticQuery(graphql`
        query ScriptureQuery {
            allWpScripture {
                nodes {
                    name
                    id
                    slug
                    parentDatabaseId
                    count
                }
            }
            wp {
                resourcesGeneralSettings {
                    resourceLibraryHeader {
                        headerImage {
                            sourceUrl
                        }
                    }
                }
            }
            allWpAd(
                filter: {adPositions: {nodes: {elemMatch: {slug: {in: ["above-index-list"]}}}}}
            ) {
                nodes {
                    id
                    title
                    adPositions {
                        nodes {
                            slug
                        }
                    }
                    adFields {
                        altText
                        endDate
                        externalLink
                        startDate
                    }
                    featuredImage {
                        node {
                            mediaItemUrl
                        }
                    }
                }
            }
        }
    `)

    const oldTestamentBooks = ['genesis', 'exodus', 'leviticus', 'numbers', 'deuteronomy', 'joshua', 'judges', 'ruth', '1-samuel', '2-samuel', '1-kings', '2-kings', '1-chronicles', '2-chronicles', 'ezra', 'nehemiah', 'esther', 'job', 'psalms', 'proverbs', 'ecclesiastes', 'song-of-solomon', 'isaiah', 'jeremiah', 'lamentations', 'ezekiel', 'daniel', 'hosea', 'joel', 'amos', 'obadiah', 'jonah', 'micah', 'nahum', 'habakkuk', 'zephaniah', 'haggai', 'zechariah', 'malachi']

    let oldTestamentObjects = []

    oldTestamentBooks.forEach(function (name, index) {
        const thisBook = data.allWpScripture.nodes.find(book => book.slug === name)
        oldTestamentObjects.push(thisBook)
    })

    const newTestamentBooks = ['matthew', 'mark', 'luke', 'john', 'acts', 'romans', '1-corinthians', '2-corinthians', 'galatians', 'ephesians', 'philippians', 'colossians', '1-thessalonians', '2-thessalonians', '1-timothy', '2-timothy', 'titus', 'philemon', 'hebrews', 'james', '1-peter', '2-peter', '1-john', '2-john', '3-john', 'jude', 'revelation']

    let newTestamentObjects = []

    newTestamentBooks.forEach(function (name, index) {
        const thisBook = data.allWpScripture.nodes.find(book => book.slug === name)
        newTestamentObjects.push(thisBook)
    })

    return (
        <Layout>
            <Seo
                title="Scriptures"
                description="Scriptures Index"
            />
            <ImageHeader title="Scripture Index"
                         image={data.wp.resourcesGeneralSettings.resourceLibraryHeader.headerImage.sourceUrl}/>
            <Breadcrumbs title="Scriptures" isResource={true}/>
            <IndexFilter taxonomy="scriptures"/>
            <AdComponent ads={data.allWpAd.nodes} position="above-index-list" />
            <section className="index index--scripture container">
                <div className="index__group index__group--scripture">
                    <div className="index__group__title">
                        <div className="index__group__title__first">
                            <h5>Old Testament</h5>
                        </div>
                    </div>
                    <ul className="index__group__children">
                        {oldTestamentObjects.map((book, index) =>
                            book?.count > 0 ?
                                <li className="index__group__children__child" key={index}>
                                    <a href={`/resources/scripture/${book.slug}`}>
                                        <i><FontAwesomeIcon icon={faBookmark}/></i>
                                        {book.name}
                                    </a>
                                </li>
                                :
                                <li className="index__group__children__child index__group__children__child--disabled"
                                    key={index}>
                                    <span>
                                        <i><FontAwesomeIcon icon={faBookmark}/></i>
                                        {book?.name}
                                    </span>
                                </li>
                        )}
                    </ul>
                </div>
                <div className="index__group index__group--scripture">
                    <div className="index__group__title">
                        <div className="index__group__title__first">
                            <h5>New Testament</h5>
                        </div>
                    </div>
                    <ul className="index__group__children">
                        {newTestamentObjects.map((book, index) =>
                            book?.count > 0 ?
                                <li className="index__group__children__child" key={index}>
                                    <a href={`/resources/scripture/${book.slug}`}>
                                        <i><FontAwesomeIcon icon={faBookmark}/></i>
                                        {book.name}
                                    </a>
                                </li>
                                :
                                <li className="index__group__children__child index__group__children__child--disabled"
                                    key={index}>
                                    <span>
                                        <i><FontAwesomeIcon icon={faBookmark}/></i>
                                        {book?.name}
                                    </span>
                                </li>
                        )}
                    </ul>
                </div>
            </section>
            <Breadcrumbs title="Scriptures" isResource={true}/>
        </Layout>
    )
}

export default ScripturesPage
